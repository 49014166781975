import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { ReserveBoothService } from './../../core/_services/booths/reserve-booth.service';
import { CustomBoothDialogComponent } from './components/custom-booth-dialog/custom-booth-dialog.component';
declare let window: any;
// import { IBoothType } from '@i-con/shared/common';
import { IBookingFormReservation } from '../../core/_models/booking-form-reservation.interface';
import { IBoothTypeReservation } from '../../core/_models/booth-type-reservation.interface';
import { IBoothType } from '../../core/_models/booth.interface';
import { IBoothTypeResponse } from '../../core/_models/responses/booth-type-response.interface';
import { BoothStateService } from '../../core/_services/booths/booth-state.service';
import { BoothService } from '../../core/_services/booths/booth.service';
// import { ISubText } from '../../shared/components/successful-api-call/interface/successful-api-call.interface';
import { successReserveData } from '../meetups-fan-sites/reserve-table/data/reserve-table-success.data';
import { IBoothTypeReservationLoggedIn, IOrderResponse, ISubText, PlatformService, StateService } from '@i-con/shared/common';

interface IReserveBoothState {
  success: boolean;
  return_msg: string;
}

const initialState: IReserveBoothState = {
  success: false,
  return_msg: '',
};

@Component({
  selector: 'app-attend-booths',
  templateUrl: './attend-booths.component.html',
  styleUrls: ['./attend-booths.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendBoothsComponent extends StateService<IReserveBoothState> implements OnInit, AfterViewInit {
  successData: ISubText = successReserveData;

  booth_types$: Observable<IBoothType[]>;
  //booth_state$: Observable<IBoothState> = this.boothState.booth_state$;

  booth_types: IBoothType[] = [];

  //booths_availability: IBoothArrangement[]; //{[key: number]: {[key: string]: IBoothArrangement}; };
  current_step: number = 1;
  queryParam = '';
  selected_booth_type_index: number = 1;
  selected_booth_type: IBoothType = {
    inStock: false,
    id: 1,
    name: '',
    referenceName: '',
    description: '',
    size: '',
    price: 0,
    color: '',
    floorSize: 0,
    boothTicketType: [
      {
        boothTypeId: 0,
        ticketTypeId: 0,
        quantity: 0,
        ticketType: {
          description: '',
          price: 0,
          discount: 0,
          onTheDoor: 0,
          discountEndsOn: new Date(),
          includes: [],
          inStock: false,
          id: 0,
          discounts: [],
          color: '',
        },
      },
    ],
    modelUrl: '',
    includes: [],
    positionWeight: 0,
    comboImageUrl: '',
    quantity: 0,
    discounts: [
      {
        id: 0,
        percentage: 0,
        name: '',
        description: '',
        startsOn: new Date(),
        endsOn: new Date(),
      },
    ],
  };

  conversionEvents: Map<number,string>;

  @ViewChild('tabitemline') divTabItemLine!: ElementRef;
  @ViewChild('tabs') divTabs!: ElementRef;
  @ViewChild('boothselector') boothSelector!: ElementRef;
  @ViewChild('header') header!: ElementRef;
  @ViewChildren('tabitems') tabitems!: QueryList<any>;

  @Input() class_name: string = 'attend_booths_page';
  @Input() phrase: string = 'Be A Part Of';
  @Input() title1: string = 'YOUR FUTURE';
  @Input() title2: string = 'CONFERENCE!';

  // @Input() selected_booth_id: string = '';

  constructor(
    private boothService: BoothService,
    private _platform: PlatformService,
    private elem: ElementRef,
    private boothState: BoothStateService,
    private router: Router,
    private reserveBoothService: ReserveBoothService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    super(initialState);
  }

  boothReservation: IBoothTypeReservation = {
    reservation: {
      boothTypes: [
        {
          boothTypeId: 0,
          quantity: 1,
        },
      ],
    },
    contact: {
      name: '',
      surname: '',
      email: '',
      termsAgreement: false,
      optinConsent: false,
    },
    billing: {
      address: '',
      country: '',
      city: '',
      postalCode: '',
      companyName: '',
      vatNumber: '',
    },
  };

  postData$: Observable<IBoothTypeResponse>;
  orderData$: Observable<IOrderResponse>;
  boothResponseState$: Observable<IReserveBoothState> = this.getState(state => state);

  selectedBoothId = 0;
  submitted = false;

  ngAfterViewInit(): void {
    this.tabitems.changes.subscribe(t => {
      this.updateUI();
    });
    this.updateUI();
  }

  ngOnInit(): void {
    this.postData$ = this.reserveBoothService.postData$;
    this.orderData$ = this.reserveBoothService.orderData$;
    // this.boothService.getBooths().subscribe((res) => {
    //   this.booths = res;

    //   this.selected_booth_type_index = 0;
    // this.selectBoothTypeByCurrentIndex();

    //   console.log(this.booths);
    // });

    this.boothResponseState$ = this.getState(state => state);

    this.booth_types$ = this.boothState.booth_types$;

    this.booth_types$.pipe(filter(booth_types => booth_types.length > 0)).subscribe(booth_types => {
      this.booth_types = booth_types;

      if (this.route.snapshot.queryParams['booth'])
        this.route.queryParams.subscribe(params => {
          this.queryParam = params['booth']?.toLowerCase();
          const booth = booth_types.find(booth => booth.name.toLowerCase() === this.queryParam);
          if (booth) {
            const cIndex = this.getBoothIndexById(booth.id!);
            // this.selected_booth_type_index = cIndex!;
            // this.selectBoothTypeByCurrentIndex();
            // this.updateUI();

            this.selected_booth_type = booth;
            this.selectBoothType(this.selected_booth_type, cIndex);
          }
        });

      //this.selectBoothTypeByCurrentIndex();
    });

    this.conversionEvents = new Map<number,string>([[1, "AW-10940770656/rjSMCIK7zoYYEODS--Ao"], [2, "AW-10940770656/5jxDCNip8YwZEODS--Ao"]]);

    // this.booth_state$.subscribe((booth_state) => {
    //  console.log(booth_state.booth_types);
    //   this.booth_types = booth_state.booth_types;
    //   this.booths_availability = booth_state.booths_availability;
    //   this.selected_booth_type_index = 0;
    //   this.selectBoothTypeByCurrentIndex();
    // });
  }

  public setSelectedBoothByDirection(direction: 'previous' | 'next'): void {
    if (direction == 'previous') {
      this.selected_booth_type_index = this.selected_booth_type_index == 0 ? this.booth_types.length - 1 : this.selected_booth_type_index - 1;
    } else if (direction == 'next') {
      this.selected_booth_type_index = this.selected_booth_type_index == this.booth_types.length - 1 ? 0 : this.selected_booth_type_index + 1;
    }
    this.selectBoothTypeByCurrentIndex();
    this.updateUI();
  }

  openCustomDialog() {
    //event?.preventDefault();
    const dialogRef = this.dialog.open(CustomBoothDialogComponent, {
      maxHeight: '80vh',
      width: '750px',
      autoFocus: false,
      panelClass: 'custom_booth_dialog',
    });
  }

  selectBoothType(booth: IBoothType, index: number): void {
    this.selected_booth_type_index = index;
    this.selectBoothTypeByCurrentIndex();
    this.updateUI();
  }

  selectBoothTypeByCurrentIndex(): void {
    this.selected_booth_type = this.booth_types[this.selected_booth_type_index];

    this.updateSelectedBoothState();
  }

  updateUI(): void {
    if (this._platform.isBrowser) {
      this.adjustTabsLinePositionBySelectedBooth();
    }
  }

  /** Move selected tab line based on selection */
  adjustTabsLinePositionBySelectedBooth(): void {
    //console.log(this.selected_booth_type);
    // if ( clickedItem == null) { return; }
    const clickedItem = this.elem.nativeElement.querySelector('[id="booth-tab-' + this.selected_booth_type.id + '"]');
    // parent of tabs item
    const tabs_left = this.divTabs.nativeElement.getBoundingClientRect()?.left;

    // clicked element width and left position
    const menu_item_width = clickedItem?.offsetWidth - 30;
    const rect = clickedItem?.getBoundingClientRect();
    const left_pos = rect?.left - tabs_left + 15;

    // adjust line width and position
    this.divTabItemLine.nativeElement.style.width = `${menu_item_width}px`;
    this.divTabItemLine.nativeElement.style.left = `${left_pos}px`;

    // to be removed
    // var backgroundColors = ['#008FBD', '#FF651F', '#FE91AB', '#E8B24B'];

    this.divTabItemLine.nativeElement.style.background = '#' + this.selected_booth_type.color; //backgroundColors[this.getRandomIntInclusive(0,3)];

    // clickedItem.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'center'
    // });
    //console.log(this.boothSelector.nativeElement.clientWidth)
    this.boothSelector.nativeElement.scrollTo({ left: left_pos, behavior: 'smooth' }); //.scrollLeft = left_pos;
  }

  /** TEMPORARY */
  // getRandomIntInclusive(min: number, max:number): number {
  //   min = Math.ceil(min);
  //   max = Math.floor(max);
  //   return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  // }

  goToStep(boothInfoObject: any): void {
    event?.preventDefault();
    this.current_step = boothInfoObject.step;
    this.selectedBoothId = boothInfoObject.selectedBoothId;
  }

  goToBoothSelection(step: any) {
    this.current_step = step.step;
    this.setState({
      success: false,
      return_msg: '',
    });
  }

  updatedBoothTypeById(updatedBoothId: any): void {
    this.selectedBoothId = updatedBoothId;
  }

  getBoothIndexById(booth_id: number): number {
    for (let i = 0; i < this.booth_types.length; i++) {
      if (this.booth_types[i].id == booth_id) {
        return i;
      }
    }
    return -1;
  }

  selectBoothTypeById(booth_id: number): void {
    for (let i = 0; i < this.booth_types.length; i++) {
      if (this.booth_types[i].id == booth_id) {
        this.selected_booth_type = this.booth_types[i];
        this.updateUI();
        this.updateSelectedBoothState();
        break;
      }
    }
  }

  // updateSelectedBoothNum(booth_id: string): void {
  //   this.selected_booth_id = booth_id;
  // }

  updateSelectedBoothState(): void {
    this.boothState.updateSelectedBoothType(this.selected_booth_type);
  }

  setBookingForm($event: IBookingFormReservation) {
    // this.boothReservation.contact = $event.bookingDetails.bookingForm;
    // this.boothReservation.billing = $event.bookingDetails.bookingForm;
    // this.boothReservation.reservation.boothTypes = [{boothTypeId: this.selectedBoothId}];
    // this.boothReservation.reservation.boothTypes[0].boothTypeId = this.selectedBoothId;

    const boothReservation: IBoothTypeReservation = {
      reservation: {
        boothTypes: [
          {
            boothTypeId: this.selectedBoothId,
            quantity: 1,
          },
        ],
      },
      contact: {
        name: $event.name,
        surname: $event.surname,
        email: $event.email,
        termsAgreement: $event.termsAgreement,
        optinConsent: $event.optinConsent,
      },
      billing: {
        address: $event.address,
        country: $event.country,
        city: $event.city,
        postalCode: $event.postalCode,
        companyName: $event.companyName,
        vatNumber: $event.vatNumber,
      },
    };

    this.sendPostRequest(boothReservation);
  }

  setBookingFormLoggedIn($event: IBoothTypeReservationLoggedIn) {
    this.reserveBoothService.reserveBoothLoggedIn($event);
    this.changeSuccessState($event, true);
  }

  changeSuccessState(bookingDetails: IBoothTypeReservation | IBoothTypeReservationLoggedIn, loggedIn: boolean) {
    if (loggedIn) {
      this.orderData$.subscribe(res => {
        if (res.response?.statusCode == 201) {
          this.setState({
            success: true,
            return_msg: '',
          });
          
        } else {
          this.setState({
            success: false,
            return_msg: res.response?.message,
          });
        }
      });
    }
    else {
      this.postData$.subscribe(res => {
        if (res.response?.statusCode == 201) {
          this.setState({
            success: true,
            return_msg: '',
          });
          
        } else {
          this.setState({
            success: false,
            return_msg: res.response?.message,
          });
        }
      });
    }
  }

  addTrackingDetails(bookingDetails: IBoothTypeReservation | IBoothTypeReservationLoggedIn) {
    window?.gtag('event', 'conversion', { send_to: this.conversionEvents.get(bookingDetails.reservation.boothTypes[0].boothTypeId) });
    window?.fbq('trackCustom', 'BoothPurchase', {
      content_category: 'booths',
      content_ids: [bookingDetails.reservation.boothTypes[0]?.boothTypeId],
      content_name: this.selected_booth_type.name,
      content_type: 'product',
      contents: [{'id': bookingDetails.reservation.boothTypes[0]?.boothTypeId, 'quantity': bookingDetails.reservation.boothTypes[0]?.quantity}]
    });
  }

  sendPostRequest(obj: IBoothTypeReservation) {
    this.submitted = true;

    // console.log(obj);

    this.reserveBoothService.reserveBoothItem(obj);
    this.changeSuccessState(obj, false);
  }
}
