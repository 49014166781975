import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './features';
import { AppPrivacyPolicyComponent } from './features/app-privacy-policy/app-privacy-policy.component';
import { AttendBoothsComponent } from './features/attend-booths/attend-booths.component';
import { AttendTicketsComponent } from './features/attend-tickets/attend-tickets.component';
import { PurchaseTicketComponent } from './features/attend-tickets/components/purchase-ticket/purchase-ticket.component';
import { AttendTicketsBundleComponent } from './features/attend-tickets/components/select-bundle/select-bundle.component';
import { AttendComponent } from './features/attend/attend.component';
import { FormAttendeeComponent } from './features/attendee-form/attendee-form';
import { CareComponent } from './features/care/care.component';

import { MykonosComponent } from './features/mykonos/mykonos.component';
import { PlanScheduleComponent } from './features/plan-schedule/plan-schedule.component';
import { PlanSpeakersComponent } from './features/plan-speakers/plan-speakers.component';
import { PlanComponent } from './features/plan/plan.component';

import {
  AccommodationComponent,
  ContactUsComponent,
  DownloadAppComponent,
  PlanEventsComponent,
  SponsorshipsComponent,
  StayAccommodationRequestLayoutComponent,
  TransportationComponent,
} from '@i-con/shared/ui';
import { FloorMapComponent } from './features/floor-map/floor-map.component';
import { QuestionnaireComponent } from './features/questionnaire/questionnaire.component';
import { ActivitiesComponent } from './features/stay-activities/activities.component';
import { StayComponent } from './features/stay/stay.component';
import { TermsAndConditionsComponent } from './features/terms-and-conditions/terms-and-conditions.component';
import { UnsubscribeComponent } from './features/unsubscribe/unsubscribe.component';
import { VisionRecapAlbumGalleryComponent } from './features/vision-recap/vision-recap-album/vision-recap-album-gallery/vision-recap-album-gallery.component';
import { VisionRecapAlbumComponent } from './features/vision-recap/vision-recap-album/vision-recap-album.component';
import { VisionRecapComponent } from './features/vision-recap/vision-recap.component';
import { VisionComponent } from './features/vision/vision.component';
import { WinningPitchComponent } from './features/winning-pitch/winning-pitch.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    data: {
      title: 'Island Conference, i-Con Conference, Affiliate Conference |',
      description:
        'Join your future affiliate conference to level up your brand reach! Island Conference is taking place on the 30th & 31st of May 2024 in Limassol, Cyprus.',
      url: 'https://island-conference.com/',
      type: 'website',
      className: 'homepage',
    },
  },
  {
    path: 'vision',
    component: VisionComponent,
    data: {
      title: ' i-Con Conference, Your Future Conference, About i-Con |',
      description: 'Curious about i-Con? This is our vision for your future affiliate marketing conference! Find out more about the people behind the event!',
      url: 'https://island-conference.com/vision',
      type: 'organization',
      className: 'vision',
    },
  },
  {
    path: 'vision/merchandise',
    children: [
      {
        path: '',
        loadChildren: () => import('./features/vision-merchandise/vision-merchandise.module').then(m => m.VisionMerchandiseModule),
      },
    ],
    data: {
      title: 'Miconos Merchandise, i-Con Apparel, Swimwear |',
      description: 'Get your Miconos-booth-inspired merchandise and rock the Cyprus Summer with the best quality apparel!',
      url: 'https://island-conference.com/vision',
      type: 'website',
      className: 'visionMerch',
    },
  },
  {
    path: 'vision/icon-recap',
    component: VisionRecapComponent,
    data: {
      title: 'i-Con 2023 Recap, Photo Albums, After Movies |',
      description: 'Take a trip down memory lane and see what i-Con 2023 was like. Get a taste of what this year’s conference will be like! ',
      url: 'https://island-conference.com/vision',
      type: 'website',
      className: 'visionRecap',
    },
  },
  {
    path: 'vision/icon-recap/albums/:tab',
    component: VisionRecapAlbumComponent,
    data: {
      title: 'i-Con 2023 & Miconos Trip Photo Albums |',
      description: 'Browse the i-Con 2023 albums and discover all the amazing moments we experienced at last year’s conference!',
      url: 'https://island-conference.com/vision',
      type: 'website',
      className: 'visionRecapAlbum',
    },
  },
  {
    path: 'vision/icon-recap/albums/photo-albums/:day/:gallery',
    component: VisionRecapAlbumGalleryComponent,
    data: {
      title: 'i-Con 2023 & Miconos Trip Photo Albums |',
      description: 'Browse the i-Con 2023 albums and discover all the amazing moments we experienced at last year’s conference!',
      url: 'https://island-conference.com/vision',
      type: 'organization',
      className: 'visionRecapAlbumGallery',
    },
  },
  {
    path: 'attend',
    component: AttendComponent,
    data: {
      title: 'Island Conference, Cyprus Affiliate Conferences, Attend i-Con |',
      description:
        'The future is in your hands! Book your Island Conference booths, sponsorships, or tickets and become an established part of our ever-growing lineup!',
      url: 'https://island-conference.com/attend',
      type: 'event',
      className: 'attend',
    },
  },
  {
    path: 'attend/booths',
    component: AttendBoothsComponent,
    data: {
      title: ' i-Con Booths, Affiliate Conference, Conference Booth |',
      description:
        "Book your i-Con booths today and let industry experts get pulled in by your brand's attractive force! To the moon and back, our booths are simply the best.",
      url: 'https://island-conference.com/attend/booths',
      type: 'product',
      className: 'attendBooths',
    },
  },
  {
    path: 'attend/tickets',
    component: AttendTicketsComponent,
    data: {
      title: 'i-Con Tickets, Island Conference, Event Tickets |',
      description:
        'Island Conference is about to open its doors to you! Don’t waste any more time, purchase your i-Con tickets now to get them at a lower price!',
      url: 'https://island-conference.com/attend/tickets',
      type: 'product',
      className: 'attendTickets',
    },
  },
  {
    path: 'attend/tickets/:type/bundle',
    component: AttendTicketsBundleComponent,
    data: {
      title: 'i-Con Ticket Bundles, The Best Deals On i-Con Tickets |',
      description: 'Get the best deals out of our i-Con tickets now! Purchase all the tickets you need for your team to attend and get a sweat deal!',
      url: 'https://island-conference.com/attend/tickets',
      type: 'product',
      className: 'attendTicketsBundle',
    },
  },
  {
    path: 'attend/tickets/:type/bundle/:quantity/purchase',
    component: PurchaseTicketComponent,
    data: {
      title: 'Island Conference Ticket Checkout |',
      description: 'Finalise the process of purchasing your i-Con tickets and join other digital marketing professionals at the conference!',
      url: 'https://island-conference.com/attend/tickets',
      type: 'product',
      className: 'attendTicketsPurchase',
    },
  },
  {
    path: 'attend/sponsorships',
    component: SponsorshipsComponent,
    data: {
      title: 'i-Con, Marketing Sponsorship, Brand Sponsorships |',
      description:
        'Give the Island Conference’s attendees a taste of your brand! Purchase one of i-Con’s sponsorships to spread the word about your goods and services!',
      url: 'https://island-conference.com/attend/sponsorships',
      type: 'website',
      className: 'sponsorships',
    },
  },
  {
    path: 'attend/exhibitors',
    //component: ExhibitorsComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/exhibitors/exhibitors.module').then(m => m.ExhibitorsModule),
      },
    ],
    data: {
      title: 'i-Con Exhibitors, Island Conference Exhibitors |',
      description:
        'Keep up to date with the latest i-Con attendees. Check out our list of exhibitors to find out all there is to know about everyone attending and supporting us!',
      url: 'https://island-conference.com/attend/exhibitors',
      type: 'website',
      className: 'attendExhibitors',
    },
  },
  {
    path: 'attend/floor-map',
    component: FloorMapComponent,
    data: {
      title: 'Find Your Way Around The i-Con Expo |',
      description:
        'Visit every single one of our booths and start networking with our exhibitors! Follow our floor map so you won’t miss a single business opportunity!',
      url: 'https://island-conference.com/attend/floor-map',
      type: 'website',
      className: 'floormap',
    },
  },
  {
    path: 'attend/winning-pitch',
    component: WinningPitchComponent,
    data: {
      title: 'i-Con 2024 Start-Ups Winning Pitch |',
      description: 'Don’t miss your chance to be featured on the floor plan of this year’s Island Conference! Take your start-up company to the next level!',
      url: 'https://island-conference.com/attend/floor-map',
      type: 'website',
      className: 'winningPitch',
    },
  },
  {
    path: 'plan',
    component: PlanComponent,
    data: {
      title: 'i-Con Conference, Schedule & Events, i-Con Plan |',
      description: "This is i-Con's plan! Work hard and party harder throughout i-Con's networking days while also cooling down with a drink by the pool!",
      url: 'https://island-conference.com/plan',
      type: 'article',
      className: 'plan',
    },
  },
  {
    path: 'plan/schedule',
    component: PlanScheduleComponent,
    data: {
      title: 'i-Con Schedule, Affiliate Conference, Marketing Conference |',
      description:
        'Don’t miss a second of our immersive schedule. Find everything from the Expo’s opening to speeches as well as i-Con’s Closing Party to the Poker Tournament!',
      url: 'https://island-conference.com/plan/schedule',
      type: 'website',
      className: 'planSchedule',
    },
  },
  {
    path: 'plan/speakers',
    component: PlanSpeakersComponent,
    data: {
      title: 'i-Con, Conference Speaker, Island Conference |',
      description:
        'Check out our list of speakers and expert individuals in the industry. i-Con gives you the opportunity to approach them and gain valuable knowledge!',
      url: 'https://island-conference.com/plan/speakers',
      type: 'website',
      className: 'planSpeakers',
    },
  },
  {
    path: 'plan/events',
    component: PlanEventsComponent,
    data: {
      title: 'i-Con Events, Affiliate Marketing Conference, Marketing Events |',
      description:
        'i-Con and the Haze Team come together to bring you a new level of parties! Meet successful individuals in your industry through i-Con’s events!',
      url: 'https://island-conference.com/plan/events',
      type: 'website',
      className: 'planEvents',
    },
  },
  {
    path: 'stay',
    component: StayComponent,
    data: {
      title: 'Island Conference, Hotels Affiliate, Limassol Hotels |',
      description:
        'Stay in the best hotels the Island Conference has to offer, book your stay with reduced rates only available to i-Con attendees and enjoy the Summer climate!',
      url: 'https://island-conference.com/stay',
      type: 'website',
      className: 'stay',
    },
  },
  {
    path: 'stay/accommodation',
    component: AccommodationComponent,
    data: {
      title: 'Book Your Stay With i-Con, Hotel Bookings, Accommodation |',
      description:
        'Reserve your stay with one of the four i-Con hotel partners for the best experience and price. Get your hotel rooms before they are fully booked!',
      url: 'https://island-conference.com/stay/accommodation',
      type: 'website',
      className: 'stayAccommodation',
    },
  },
  {
    path: 'stay/accommodation/request/:hotel_ref_name',
    component: StayAccommodationRequestLayoutComponent,
    data: {
      title: 'Book Your Stay With i-Con, Hotel Bookings, Accommodation |',
      description:
        'Reserve your stay with one of the four i-Con hotel partners for the best experience and price. Get your hotel rooms before they are fully booked!',
      url: 'https://island-conference.com/stay/accommodation',
      type: 'website',
      className: 'stayAccommodationRequest',
    },
  },
  {
    path: 'stay/transportation',
    component: TransportationComponent,
    data: {
      title: 'Book Your Transportation With i-Con, Hotel Bookings, Accommodation |',
      description: 'Reserve your private ride with us and make sure that your transportation needs during the days of the conference will be taken care of!',
      url: 'https://island-conference.com/stay/accommodation',
      type: 'website',
      className: 'transportation',
    },
  },
  {
    path: 'stay/activities',
    component: ActivitiesComponent,
    data: {
      title: 'Discover The Beauty Of Limassol Through i-Con |',
      description: 'Want to spend some of your free time discovering the secrets of Limassol? Check out our top-tier recommendations for the city!',
      url: 'https://island-conference.com/stay/activities',
      type: 'website',
      className: 'activities',
    },
  },
  {
    path: 'meetups',
    children: [
      {
        path: '',
        loadChildren: () => import('./features/meetups/meetups-routing.module').then(m => m.MeetupsRoutingModule),
      },
    ],
    data: {
      title: 'i-Con Meetups, Fan Sites, SEO & Miconos |',
      description:
        'Join us at the meetup zone and expand your horizons with the help of some of our experts! Tune in for the latest trends and new tactics to drive traffic!',
      url: 'https://island-conference.com/stay/accommodation',
      type: 'website',
      // className: 'meetups',
    },
  },
  {
    path: 'exhibitors',
    redirectTo: 'attend/exhibitors',
  },
  {
    path: 'contact',
    component: ContactUsComponent,
    data: {
      title: 'i-Con Contact, Island Conference, i-Con Information |',
      description: 'Want to find out more about i-Con? Our team is on stand-by and ready to help you with any questions you have or help you may need.',
      url: 'https://island-conference.com/contact',
      type: 'website',
      className: 'contactUs',
    },
  },
  // {
  //   path: 'media-partners',
  //   component: MediaPartnersComponent,
  //   data: {
  //     title: 'i-Con Media Partners, Media Relations, Business Partners |',
  //     description: 'Find out more about our Media Partners! Their combined efforts helped spread the word about i-Con and our first affiliate marketing event!',
  //     url: 'https://island-conference.com/media-partners',
  //     type: 'website',
  //     className: 'mediaPartner',
  //   },
  // },
  {
    path: 'faq',
    children: [
      {
        path: '',

        loadChildren: () => import('@i-con/shared/ui').then(m => m.FaqModule),
      },
    ],
    data: {
      title: 'i-Con, Frequently Asked Questions, FAQ, Conference Info |',
      description:
        'We can answer all your questions! Check out our FAQ section for information on tickets, speeches, hotel bookings and everything in between!',
      url: 'https://island-conference.com/faq',
      type: 'article',
      className: 'faq',
    },
  },
  {
    path: 'stay/care',
    component: CareComponent,
    data: {
      title: 'i-Con, Reuse Reduce Recycle, We Care, Save The Planet |',
      description:
        'The team behind Island Conference cares for the environment, and have taken steps to provide you with a green, waste-free affiliate conference!',
      url: 'https://island-conference.com/care',
      type: 'website',
      className: 'care',
    },
  },
  {
    path: 'miconos',
    component: MykonosComponent,
    data: {
      title: 'i-Con, Mykonos Time, Affiliate Conference |',
      description:
        'i-Con takes your business owners and C-Level executives on an unforgivable trip to Mykonos! If you want to find out the details, add your name in the VIP list!',
      url: 'https://island-conference.com/miconos',
      type: 'place',
      className: 'mykonos',
    },
  },
  {
    path: 'privacy-policy',
    component: AppPrivacyPolicyComponent,
    data: {
      title: 'Island Conference Privacy Policy |',
      description: 'Read our privacy policy and find out all the actions we take to keep you safe while using the island-conference.com website.',
      url: 'https://island-conference.com/app-privacy-policy',
      type: 'website',
      className: 'privacy',
    },
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    data: {
      title: 'Island Conference Terms and Conditions |',
      description: ' Read our terms and conditions to find out more about our guidelines and code of conduct when it comes to the conference.',
      url: 'https://island-conference.com/terms-and-conditions',
      type: 'website',
      className: 'terms',
    },
  },
  {
    path: 'attendee-form/:id',
    component: FormAttendeeComponent,
    data: {
      title: 'Attendee Form',
      description: 'Fill in your info, so we can prepare your Exhibitor / Attendee Passes!',
      url: 'https://island-conference.com/attendee-form',
      type: 'website',
      className: 'formAttende',
    },
  },
  {
    path: 'download-app',
    component: DownloadAppComponent,
    data: {
      title: 'Download The i-Con Networking App Now |',
      description:
        'Download our networking app today and put i-Con in the palm of your hand! Arrange meetings, stay informed, and be on time by getting the i-Con app now!',
      url: 'https://island-conference.com/download-app',
      type: 'website',
      className: 'download',
    },
  },
  // {
  //   path: 'survey/:id',
  //   component: DynamicSurveyComponent,
  //   data: {
  //     title: 'i Con, i Con Survey, Feedback |',
  //     description: 'Survey for icon',
  //     url: 'https://island-conference.com/survey',
  //     type: 'website',
  //     className: 'dynamicSurvey',
  //   },
  // },
  {
    path: 'unsubscribe/:emailAddress',
    component: UnsubscribeComponent,
    data: {
      title: 'i-Con, Download Mobile App |',
      description: 'Download the free mobile from the store to be updated on the latest news about the conference',
      url: 'https://island-conference.com/unsubscribe',
      type: 'website',
      className: 'unsubscribe',
    },
  },
  {
    path: 'survey/:emailAddress',
    component: QuestionnaireComponent,
    data: {
      title: 'i Con, i Con Survey, Feedback |',
      description: 'Survey for icon',
      url: 'https://island-conference.com/survey',
      type: 'website',
      className: 'questionnaire',
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: 'Error, Page Not Found |',
      description:
        'It looks like you’ve encountered an error. The i-Con team is working on resolving the issue as soon as possible. In the meantime, visit Island-Conference.com.',
      url: 'https://island-conference.com/404',
      type: 'website',
      className: 'notFound',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
