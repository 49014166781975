import { ISubText } from "@i-con/shared/common";


export const successData: ISubText = {
  subHeading: 'Thank you for your Purchase',
  content: `We would like to inform you that the relevant receipt for your recent transaction has been forwarded to your inbox. Additionally, we are pleased to share that you will soon receive a separate email containing the booking code for our i-Con portal. Please note that the i-Con portal is currently under development, and we are expecting it to go live very soon. Once the portal is officially released, you will receive a notification about the availability of access to your account. This will grant you the opportunity to fill in the required information for each attendee associated with your booking.`,
  note: 'Trouble finding our email? Check your spam folder or contact our customer support team.',
};

export const successDataNewRes: ISubText = {
  subHeading: 'Thank you for your Purchase',
  content: `We would like to inform you that the relevant receipt for your recent transaction has been forwarded to your inbox. Additionally, we are pleased to share that you will soon receive a separate email containing the booking code for our i-Con portal. You will be able to access it by clicking the "My Portal" button at the top right corner of the website. Through the panel,  you'll be able to view your reservation's details and fill in the required information associated with your bookings.`,
  note: 'Trouble finding our email? Check your spam folder or contact our customer support team.',
};

export const successDataExistingRes: ISubText = {
  subHeading: 'Thank you for your Purchase',
  content: `We would like to inform you that the relevant receipt for your recent transaction has been forwarded to your inbox. Additionally, we would like to let you know that you can access the portal straight away! You can do so by clicking the "My Portal" button at the top right corner of the website, and using your existing booking code. Through the panel,  you'll be able to view your reservation's details and fill in the required information associated with your bookings.`,
  note: 'Trouble finding our email? Check your spam folder or contact our customer support team.',
};

export const successReserveData: ISubText = {
  subHeading: 'Thank you for your Reservation',
  content: `The confirmation of your reservation along with the relevant invoice have been forwarded to your email inbox. Kind reminder to check your spam folder if the email does not seem to be delivered. Alternatively, feel free to contact our team with any issues you might have regarding your booth reservations.`,
  note: 'Please keep in mind that reservations are subject to be cancelled if they are not paid in full within 7 days after being booked.',
};
