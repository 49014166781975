import { ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Scroll } from '@angular/router';
import { filter } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderInterceptor } from './core/_helpers/header.interceptor';
import { InjectorHolderService } from './core/_services/utils/injector-holder.service';
// import { PlatformService } from '../../../../libs/i-con.shared/common/src/lib/services/platform.service';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';

import { PlatformService, playlistStreamConfig } from '@i-con/shared/common';
import { MusicPlayerModule, RootLayoutComponent } from '@i-con/shared/ui';



@NgModule({
  declarations: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppComponent,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    FeaturesModule,
    SharedModule,
    BrowserAnimationsModule,
    RouterModule,
    RootLayoutComponent,
    //sigleton services
    MusicPlayerModule.forRoot(playlistStreamConfig),
  ],
  exports: [MusicPlayerModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    provideClientHydration()
    //{ provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    
  ],
  //bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    router: Router,
    viewportScroller: ViewportScroller,
    private platformService: PlatformService
  ) {
    router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: any) => {
        if (e.anchor) {
          // anchor navigation
          /* setTimeout is the core line to solve the solution */
          const el = document.getElementById(e.anchor);
          setTimeout(() => {
            return el?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'start',
            });
          });
        } else if (e?.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e?.position);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      });

    InjectorHolderService.injector = Injector.create({
      providers: [
        {
          provide: PlatformService,
          useValue: this.platformService,
          deps: [],
        },
      ],
    });
  }
}
