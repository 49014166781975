import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { JoinPipe } from '../core/_helpers/join.pipe';
import { PluckPipe } from '../core/_helpers/pluck.pipe';
import { DynamicDialogComponent } from '../shared/components/dynamic-dialog/dynamic-dialog.component';
import { SharedModule } from './../shared/shared.module';
import { AppPrivacyPolicyComponent } from './app-privacy-policy/app-privacy-policy.component';
import { AttendBoothsComponent } from './attend-booths/attend-booths.component';
import { CustomBoothDialogComponent } from './attend-booths/components/custom-booth-dialog/custom-booth-dialog.component';
import { MapSvgComponent, WheelScrollDirective } from './attend-booths/components/map-svg/map-svg.component';
import { ReserveBookingFormComponent } from './attend-booths/components/reserve-booking-form/reserve-booking-form.component';
import { ReserveBoothComponent } from './attend-booths/components/reserve-booth/reserve-booth.component';
import { ReservePrimaryContactFormComponent } from './attend-booths/components/reserve-primary-contact-form/reserve-primary-contact-form.component';
import { SelectBoothAndReserveComponent } from './attend-booths/components/select-booth-and-reserve/select-booth-and-reserve.component';
import { SelectBoothTypeComponent } from './attend-booths/components/select-booth-type/select-booth-type.component';
import { SoldoutListComponent } from './attend-booths/components/soldout-list/soldout-list.component';
import { AttendBoothsStepNavComponent } from './attend-booths/components/step-nav/attend-booths-step-nav.component';
import { AttendTicketsComponent } from './attend-tickets/attend-tickets.component';
import { AttendeeFormComponent } from './attend-tickets/components/create-attendees/attendee-form/attendee-form.component';
import { CreateAttendeesComponent } from './attend-tickets/components/create-attendees/create-attendees.component';
import { CreatePrimaryContactComponent } from './attend-tickets/components/create-primary-contact/create-primary-contact.component';
import { SelectQuantityComponent } from './attend-tickets/components/select-quantity/select-quantity.component';
import { SelectTicketTypeComponent } from './attend-tickets/components/select-ticket-type/select-ticket-type.component';
import { AttendComponent } from './attend/attend.component';
import { CareComponent } from './care/care.component';
import { DynamicSurveyComponent } from './dynamic-survey/dynamic-survey.component';
import { ExhibitorsRoutingModule } from './exhibitors/exhibitors-routing.module';
import { ExhibitorsModule } from './exhibitors/exhibitors.module';
import { FloorMapComponent } from './floor-map/floor-map.component';
import { HomepageComponent } from './homepage/homepage.component';
import { MediaPartnersComponent } from './media-partners/media-partners.component';
import { MeetupsModule } from './meetups/meetups.module';
import { MykonosComponent } from './mykonos/mykonos.component';
import { AccommodationComponent, BookingFormGroupComponent, ContactUsComponent, DownloadAppComponent, EventDialogFormComponent, FooterComponent, GuestListFormComponent, LoadingSpinnerComponent, NavButtonComponent, PlanEventsComponent,  PrimaryContactFormComponent, SelectComponent, StayAccommodationListComponent, StayAccommodationRequestComponent, StayAccommodationRequestLayoutComponent, StayCreatePrimaryContactComponent, SuccessfulApiCallComponent, TransportationComponent, TransportationEventCardComponent, VipTableFormComponent } from '@i-con/shared/ui';
import { BoothsSectionComponent } from './plan-schedule/booths-section/booths-section.component';
import { PlanScheduleComponent } from './plan-schedule/plan-schedule.component';
import { ScheduleCardComponent } from './plan-schedule/schedule-card/schedule-card.component';
import { SpeechCardComponent } from './plan-schedule/speech-card/speech-card.component';
import { BecomeASpeakerComponent } from './plan-speakers/become-a-speaker/become-a-speaker.component';
import { PlanSpeakersComponent } from './plan-speakers/plan-speakers.component';
import { SpeakersCardComponent } from './plan-speakers/speakers-card/speakers-card.component';
import { PlanComponent } from './plan/plan.component';
// import { PopupFormComponent } from './sponsorships/popup-form/popup-form.component';
// import { SponsorshipCreatePrimaryContactComponent } from './sponsorships/sponsorship-create-primary-contact/sponsorship-create-primary-contact';
// import { SponsorshipsListComponent } from './sponsorships/sponsorships-list/sponsorships-list.component';
// import { SponsorshipsComponent } from './sponsorships/sponsorships.component';
import { ActivitiesComponent } from './stay-activities/activities.component';
import { ActivityItemComponent } from './stay-activities/components/activity-item.component';
// import { TransportationEventCardComponent } from './stay-transportation/components/transportation-event-card/transportation-event-card.component';
// import { TransportationComponent } from './stay-transportation/transportation.component';
import { StayComponent } from './stay/stay.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TripReservationComponent } from './trip-reservation/trip-reservation.component';
import { VisionMerchandiseModule } from './vision-merchandise/vision-merchandise.module';
import { VisionRecapComponent } from './vision-recap/vision-recap.component';
import { VisionComponent } from './vision/vision.component';
import { WinningPitchCardComponent } from './winning-pitch/winning-pitch-card/winning-pitch-card.component';
import { WinningPitchComponent } from './winning-pitch/winning-pitch.component';
// import { AttendTicketsBundleComponent } from './attend-tickets-bundle/attend-tickets-bundle.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { AuthorizationService } from '@i-con/shared/common';
import { LoginDialogComponent } from '@i-con/shared/ui';
import { ExhibitorsLegendComponent } from './floor-map/exhibitors-legend/exhibitors-legend.component';
import { TabContentComponent } from './floor-map/tab-content/tab-content.component';
import { CTAButtonsComponent } from './questionnaire/components/CTAButtons/CTAButtons.component';
import { AttendPresentationsComponent } from './questionnaire/components/attendpresentations/attendpresentations.component';
import { EventsLikeComponent } from './questionnaire/components/events-like/events-like.component';
import { EventsSatisfiedComponent } from './questionnaire/components/events-satisfied/events-satisfied.component';
import { FindOutComponent } from './questionnaire/components/findout/findout.component';
import { GoodTimeComponent } from './questionnaire/components/good-time/good-time.component';
import { HazeEventsComponent } from './questionnaire/components/haze-events/haze-events.component';
import { HowHappyComponent } from './questionnaire/components/howhappy/howhappy.component';
import { ICon2025Component } from './questionnaire/components/i-con-2025/i-con-2025.component';
import { ImprovementsComponent } from './questionnaire/components/improvements/improvements.component';
import { NotAttendComponent } from './questionnaire/components/notattend/notattend.component';
import { RateFeaturesComponent } from './questionnaire/components/ratefeatures/ratefeatures.component';
import { SeeYouNextYearComponent } from './questionnaire/components/see-you-next-year/see-you-next-year.component';
import { SpeakerCardComponent } from './questionnaire/components/speakers/speaker-card/speaker-card.component';
import { SpeakersComponent } from './questionnaire/components/speakers/speakers.component';
import { WelcomeComponent } from './questionnaire/components/welcome/welcome.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';

@NgModule({
  declarations: [
    HomepageComponent,
    VisionComponent,
    AttendComponent,
    PlanComponent,
    StayComponent,
    CareComponent,
    AttendBoothsComponent,
    SelectBoothTypeComponent,
    SelectBoothAndReserveComponent,
    MapSvgComponent,
    AttendBoothsStepNavComponent,
    ReserveBoothComponent,
    AttendTicketsComponent,
    // AttendTicketsBundleComponent,
    PlanScheduleComponent,
    WheelScrollDirective,
    SelectTicketTypeComponent,
    SelectQuantityComponent,
    //ExhibitorsComponent,
    //CreatePrimaryContactComponent,
    //ReserveTicketTypeComponent,
    //SelectReserveTicketsComponent,
    SelectQuantityComponent,
    PlanScheduleComponent,
    PlanSpeakersComponent,
    SpeakersCardComponent,
    WheelScrollDirective,
    DynamicDialogComponent,
    MykonosComponent,
    TripReservationComponent,
    AttendeeFormComponent,
    CreateAttendeesComponent,
    CustomBoothDialogComponent,
    CreatePrimaryContactComponent,
    PluckPipe,
    JoinPipe,
    // SponsorshipsComponent,
    // SponsorshipsListComponent,
    // SponsorshipCreatePrimaryContactComponent,
    ReservePrimaryContactFormComponent,
    ReserveBookingFormComponent,
    SoldoutListComponent,
    MediaPartnersComponent,
    AppPrivacyPolicyComponent,
    FloorMapComponent,
    DynamicSurveyComponent,
    VisionRecapComponent,
    WinningPitchComponent,
    WinningPitchCardComponent,
    // TransportationComponent,
    // TransportationEventCardComponent,
    ActivitiesComponent,
    ActivityItemComponent,
    // PopupFormComponent,
    BecomeASpeakerComponent,
    TermsAndConditionsComponent,
    ScheduleCardComponent,
    ScheduleCardComponent,
    BoothsSectionComponent,
    SpeechCardComponent,
    BoothsSectionComponent,
    ExhibitorsLegendComponent,
    TabContentComponent,
    QuestionnaireComponent,
    WelcomeComponent,
    HowHappyComponent,
    CTAButtonsComponent,
    FindOutComponent,
    RateFeaturesComponent,
    AttendPresentationsComponent,
    SpeakersComponent,
    NotAttendComponent,
    HazeEventsComponent,
    SpeakerCardComponent,
    GoodTimeComponent,
    EventsLikeComponent,
    EventsSatisfiedComponent,
    ImprovementsComponent,
    ICon2025Component,
    SeeYouNextYearComponent,
  ],
  providers: [
    // {
    // 	provide: HAMMER_GESTURE_CONFIG,
    // 	useClass: hammerConfig,
    // },
    AuthorizationService,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    RouterModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatExpansionModule,
    // HammerModule,
    NgxIntlTelInputModule,
    SlickCarouselModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCheckboxModule,
    VisionMerchandiseModule,
    MeetupsModule,
    ExhibitorsModule,
    ExhibitorsRoutingModule,
    BookingFormGroupComponent,
    LoginDialogComponent,
    AccommodationComponent,
    StayCreatePrimaryContactComponent,
    StayAccommodationListComponent,
    StayAccommodationRequestLayoutComponent,
    StayAccommodationRequestComponent,
    SelectComponent,
    PlanEventsComponent,
    ContactUsComponent,
    DownloadAppComponent,
    EventDialogFormComponent,
    FooterComponent,
    VipTableFormComponent,
    GuestListFormComponent,
    LoadingSpinnerComponent,
    NavButtonComponent,
    PrimaryContactFormComponent,
    TransportationComponent,
    TransportationEventCardComponent
  ],
  exports: [VisionRecapComponent, WinningPitchComponent, WinningPitchCardComponent, TransportationComponent, ActivitiesComponent, ActivityItemComponent],
})
export class FeaturesModule {}
