import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { ITicketBundleType } from '../../../../core/_models/ticket-bundle.interface';
import { ITicketType } from '../../../../core/_models/ticket.interface';
import { TicketBundleStateService } from '../../../../core/_services/tickets/ticket-bundle-state.service';
import { TicketStateService } from '../../../../core/_services/tickets/ticket-state.service';
import { UrlTrackService } from '../../../../shared/services/url-track.service';
import { SharedModule } from '../../../../shared/shared.module';
import { BundleCardComponent } from '../bundle-card/bundle-card.component';

@Component({
  selector: 'select-bundle',
  templateUrl: './select-bundle.component.html',
  standalone: true,
  styleUrl: './select-bundle.component.scss',
  imports: [MatTabsModule, CommonModule, SharedModule, BundleCardComponent],
})
export class AttendTicketsBundleComponent implements OnInit {
  class_name = 'attend-tickets-select-bundle';
  phrase = `Don't Forget Your`;
  title1 = `CONFERENCE TICKETS!`;
  ticketTypeValid: boolean = false;
  validTicketTypes: string[] = [];
  tabIndex = 0;
  allBundles: ITicketBundleType[];
  selectedBundles: ITicketBundleType[];
  bundle_types$: Observable<ITicketBundleType[]>;
  ticketTypes: ITicketType[] = [];
  previousUrl: string = '';
  fragmentPreviousUrl: string = '';
  loading: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private urlTrackService: UrlTrackService,
    private readonly ticketStateService: TicketStateService,
    private readonly ticketBundleStateService: TicketBundleStateService,
    private ref: ChangeDetectorRef
  ) {
    
    this.ticketStateService.ticket_types$.pipe(map(
      tickets => tickets.filter(ticket => ticket.id !== 2)
    )).subscribe(tickets => {
      console.log(tickets);
      this.ticketTypes = tickets;
      this.validTicketTypes = [...Array(tickets.length).keys()].map(i => i + 1).map(String);
      this.ref.markForCheck();
    });
  

    this.route.paramMap.subscribe((params: ParamMap) => {
      const param = params.get('type')?.toLowerCase();
      const index = this.validTicketTypes.indexOf(param!);
      if (index !== -1) {
        this.ticketTypeValid = true;
        this.tabIndex = index;
      }
    });
  }

  ngOnInit(): void {
    this.bundle_types$ = this.ticketBundleStateService.bundle_types$;
    this.bundle_types$.subscribe(resp => {
      this.allBundles = resp;
      this.selectedBundles = this.allBundles.filter(item => item.ticket.id === this.tabIndex + 1);
      if(this.selectedBundles.length > 0){
        this.ticketStateService.setSelectedTicketType(this.selectedBundles[0].ticket);
        this.loading = false;
      }
    });
  }

  tabChange() {
    this.selectedBundles = this.allBundles.filter(item => item.ticket.id === this.tabIndex + 1);
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.router.navigateByUrl(this.router.url.replace(params.get('type')!, this.validTicketTypes[this.tabIndex]));
      this.ticketStateService.setSelectedTicketType(this.selectedBundles[0].ticket);
    });
  }

  backClick() {
    this.urlTrackService.previousUrl$.subscribe((previousUrl: string) => {
      const index = previousUrl.indexOf('#');
      this.previousUrl = index === -1 ? previousUrl : previousUrl.substring(0, previousUrl.indexOf('#'));
      if (index != -1) this.fragmentPreviousUrl = previousUrl.substring(previousUrl.indexOf('#') + 1);
    });
    this.router.navigate([this.previousUrl], {
      relativeTo: this.route,
      fragment: this.fragmentPreviousUrl.length === 0 ? undefined : this.fragmentPreviousUrl,
    });

    this.router.navigate([this.previousUrl.includes('tickets') ? `attend/tickets` : `attend`]);
  }

  getColorName(index: number) {
    switch (index) {
      case 0:
        return 'purple';
      case 1:
        return 'green';
      case 2:
        return 'orange';
      default:
        return '';
    }
  }
}
